@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("app/asset/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("app/asset/fonts/SFProDisplay-Regular.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display semibold";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("app/asset/fonts/SFProDisplay-Semibold.woff2") format("woff2"),
    url("app/asset/fonts/SFProDisplay-Semibold.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display bold";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("app/asset/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("app/asset/fonts/SFProDisplay-Bold.woff") format("woff");
}

body {
  margin: 0 !important;
}
